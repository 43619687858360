<template>
  <v-row :style="{ width: '100%' }">
    <v-col :cols="6">
      <CreditsTable :append="append" :customerEntityId="custData.customerEntityId" />
    </v-col>
    <v-col :cols="6" class="text-center">
      <h1>Credits Selected</h1>

      <BaseForm class="pa-0 flex-column">
        <template v-slot:fields>
          <v-row class="overflow-y-auto" :style="{ maxHeight: '20vh' }">
            <v-col
              cols="12"
              class="px-1 py-1 dark_blue"
              v-for="creditSelectedInfo in data"
              :key="creditSelectedInfo.invoice_no"
            >
              <v-row>
                <v-col class="pa-1">
                  <v-text-field
                    v-model="creditSelectedInfo.invoice_no"
                    type="text"
                    outlined
                    dense
                    background-color="darker_blue"
                    color="white"
                    label="Invoice No."
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col class="pa-1">
                  <ValidationProvider
                    :rules="`required|max_num:${creditSelectedInfo.max}`"
                    name="Balance"
                    v-slot="{ errors, valid }"
                    immediate
                  >
                    <v-text-field
                      v-model="creditSelectedInfo.balance"
                      type="text"
                      outlined
                      dense
                      background-color="darker_blue"
                      color="white"
                      label="Balance"
                      :hide-details="valid"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </BaseForm>
    </v-col>
  </v-row>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import CreditsTable from "@/components/OM/Details/CreditsTable.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  props: ["step", "tableHeigth", "append", "custData", "creditsSelected"],
  components: {
    CreditsTable,
    BaseForm,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapState({
      credits: (state) => state.moduleOrders.credits,
    }),
  },
  async created() {
    if (this.credits.length) {
      this.data = JSON.parse(JSON.stringify(this.creditsSelected));
    } else {
      this.$emit("nextStep");
    }
  },
  data() {
    return {
      data: [],
    };
  },

  watch: {
    credits: {
      deep: true,
      handler() {
        let creditsSelected = this.credits.filter((x) => x.selected);
        this.data = JSON.parse(JSON.stringify(creditsSelected));
      },
    },
    data: {
      immediate: true,
      deep: true,
      async handler() {
        this.$emit("setCreditsSelected", this.data);
      },
    },
  },
  methods: {
    ...mapMutations({
      setCredits: "moduleOrders/setCredits",
    }),
  },
};
</script>

<style></style>
