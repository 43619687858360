<template>
  <div class="form-inventory-wrapper">
    <v-row class="text-center justify-center pb-5 text-subtitle-1 font-weight-bold">
      <span>ADD INVENTORY</span>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        v-for="{ title, value, type, disabled, dropdownValues } in UIInventory"
        :key="value"
        class="px-2 py-0"
      >
        <v-row>
          <span>
            {{ title }}
          </span>
        </v-row>
        <v-row>
          <v-autocomplete
            v-if="type === 'dropdown'"
            v-model="data[value]"
            :search-input="dropdownValues.inputSearch"
            :items="getInfo(dropdownValues.options)"
            :item-text="dropdownValues.key"
            :item-value="dropdownValues.value"
            single-line
            dense
            outlined
            hide-details
            class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
            :return-object="dropdownValues.returnObject"
            :loading="loading[value]"
            @change="dropdownValues.changeEvent && dropdownValues.changeEvent(value)"
          />
          <v-text-field
            class="text-center ma-0"
            outlined
            dense
            v-else
            :disabled="disabled"
            v-model="data[value]"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" class="text-center py-3">
        <span :style="{ color: data.Depot === 'Add New Depot' ? 'white' : 'gray' }">
          Add new Depot</span
        >
      </v-col>
      <v-col
        cols="6"
        v-for="{ title, value, type } in UIDepot"
        :key="value"
        class="px-2 py-0"
      >
        <DatePicker
          v-if="type === 'DatePicker'"
          v-model="data[value]"
          :data="data"
          :keyData="value"
          title="* Date From"
          :existingModel="data[value]"
          toFormat="yyyy-LL-dd"
          :disabled="data.Depot !== 'Add New Depot'"
        ></DatePicker>
        <v-text-field
          v-else
          class="text-center ma-0"
          outlined
          dense
          v-model="data[value]"
          :disabled="data.Depot !== 'Add New Depot'"
        >
          <template v-slot:label>
            {{ title }}
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import {
  api_get_all_countries,
  api_get_states_by_country,
  api_get_cities_by_state,
} from "@/helpers/api/SC/index.js";
export default {
  props: ["data"],
  components: {
    DatePicker,
  },
  async created() {
    try {
      this.loading.Country = true;
      this.loading.State = true;
      this.loading.City = true;
      await this.getDepotsAll();
      const { data } = await api_get_all_countries();

      this.data["Country"] = {
        0: "US",
        1: "United States",
        2: 233,
        iso2: "US",
        name: "United States",
        id: 233,
      };
      this.countries = data.data;
      this.loading.Country = false;

      var states = await api_get_states_by_country(this.data.Country.id);

      this.loading.State = false;

      this.states = states;
      this.data["State"] = this.states[0];

      const cities = await api_get_cities_by_state(this.data.State.id);
      this.cities = cities;
      this.data["City"] = this.cities[0];
      this.loading.City = false;
    } catch (error) {}
  },
  data() {
    return {
      UIInventory: [
        { title: "Size", value: "Size" },
        { title: "Type", value: "Type" },
        { title: "Condition", value: "Cndtn" },
        {
          title: "Country",
          value: "Country",
          type: "dropdown",
          dropdownValues: {
            options: "countries",
            inputSearch: "",
            key: "name",
            returnObject: true,
            changeEvent: this.getData,
          },
        },
        {
          title: "State/Province",
          value: "State",
          type: "dropdown",
          dropdownValues: {
            options: "states",
            loading: false,
            inputSearch: "",
            key: "name",
            returnObject: true,
            changeEvent: this.getData,
          },
        },
        {
          title: "City",
          value: "City",
          type: "dropdown",

          dropdownValues: {
            options: "cities",
            loading: false,
            inputSearch: "",
            key: "name",
            returnObject: true,
            changeEvent: this.getData,
          },
        },
        { title: "Qty", value: "qtyTBD" },
        {
          title: "Depot",
          value: "Depot",
          type: "dropdown",
          dropdownValues: {
            options: "allDepots",
            inputSearch: "",
            key: "code",
            value: "code",
            // returnObject: true,
          },
        },
      ],

      UIDepot: [
        {
          title: "* Code (Max 10)",
          value: "code",
          type: "Text",
        },

        {
          title: "* Name",
          value: "name",
          type: "Text",
        },

        {
          title: "* Email",
          value: "email",
          type: "Text",
        },
        {
          title: "Address",
          value: "address",
          type: "Text",
        },
        {
          title: "* Date From",
          value: "EffectiveFrom",
          type: "DatePicker",
        },
      ],
      countries: [],
      states: [],
      cities: [],
      loading: {
        Country: false,
        State: false,
        City: false,
      },
    };
  },
  computed: {
    ...mapState({
      allDepots: (state) => state.moduleOrders.allDepots,
    }),
  },
  methods: {
    ...mapActions({
      getDepotsAll: "moduleOrders/getDepotsAll",
    }),
    getInfo(key) {
      if (key == "allDepots") {
        return [{ code: "Add New Depot" }, ...this[key]];
      }
      return this[key];
    },
    async getData(option) {
      switch (option) {
        case "Country":
          if (this.data.Country && this.data.Country.id !== "") {
            this.loading.State = true;
            var states = await api_get_states_by_country(this.data.Country.id);

            this.loading.State = false;

            this.states = states;
          }

          break;
        case "State":
          this.loading.City = true;
          this.cities = [];
          const cities = await api_get_cities_by_state(this.data.State.id);

          if (cities.length) {
            this.cities = cities;
          }
          this.loading.City = false;

          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-inventory-wrapper {
  width: 50%;
}
</style>
