<template>
  <v-menu
    offset-y
    :close-on-click="false"
    :close-on-content-click="false"
    min-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-on="on"><v-icon>mdi-filter-variant</v-icon></v-btn>
    </template>
    <v-card class="mx-auto darker_blue">
      <v-card-title tag="span" class="pa-2 font-weight-light">
        Filter by details
      </v-card-title>

      <v-card-text class="pa-0 px-2">
        <v-row v-for="(filter, index) in filters" :key="index">
          <v-col class="d-flex justify-center align-center">
            <!-- <span
              class="text-subtitle-2 px-2"
              style="width: 100px"
              v-if="index === 0 || index !== 1"
            >
              {{ index === 0 ? "where" : filterOption }}
            </span> -->
            <!-- <v-select
              v-else
              style="width: 100px"
              v-model="filterOption"
              :items="['and', 'or']"
              flat
              outlined
              single-line
              dense
              hide-details
            >
            </v-select> -->

            <v-autocomplete
              v-model="filter.field"
              :items="columns"
              color="white"
              dense
              outlined
              flat
              background-color="#20212E"
              hide-details
              class="rounded-0 lighten-2 pa-0 ma-0 text-center"
              item-text="text"
              item-value="value"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filter.condition"
              :items="conditionals"
              color="white"
              dense
              outlined
              flat
              background-color="#20212E"
              hide-details
              class="rounded-0 lighten-2 pa-0 ma-0 text-center"
              item-text="text"
              item-value="value"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              v-model="filter.value"
              hide-details
              dense
              outlined
              single-line
              class="ma-0 rounded-5 text-field__calendar"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="auto">
            <v-btn icon @click="removeFilter(index)"><v-icon>mdi-delete</v-icon></v-btn>
          </v-col> -->
        </v-row>
      </v-card-text>

      <!-- <v-card-actions>
        <v-row>
          <v-col>
            <v-btn icon @click="addNewFilter"><v-icon>mdi-plus</v-icon></v-btn>
            <span>Add condition</span>
          </v-col>
        </v-row>
      </v-card-actions> -->
    </v-card>
  </v-menu>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: ["columns"],
  data() {
    return {
      filters: [],
      filterOption: "and",
      conditionals: [
        { text: "contains...", value: "contains" },
        { text: "does not contain...", value: "does_not_contain" },
        { text: "is...", value: "is" },
        { text: "is not...", value: "is_not" },
      ],
      dataFiltered: [],
    };
  },
  created() {
    this.addNewFilter();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.setFilters(this.filters);
        // this.filterData();
      },
    },
    filterOption() {
      this.setFilters(this.filters);

      // this.filterData();
    },
  },
  methods: {
    ...mapMutations({
      setAllOrders: "moduleOrders/setAllOrders",
      setFilters: "moduleOrders/setFilters",
    }),
    addNewFilter() {
      const { value } = this.columns[0];
      this.filters.push({ condition: "contains", field: value, value: "" });
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
