<template>
  <v-row :style="{ gap: '0px 5px' }" class="dark-blue pa-2 items-center">
    <v-col cols="1">
      <v-btn class="button elevation-5" @click="returnAllOrders">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>

    <v-col class="ma-1 pa-0 text-center d-flex justify-end" v-if="auctionFlag">
      <v-row justify="end" style="gap: 5px" v-if="!auctionId">
        <v-col cols="auto">
          <v-btn class="button elevation-5" color="primary" @click="$emit('loadLots')">
            load lots from json
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn class="button elevation-5" color="primary" @click="$emit('saveAsDraft')">
            save as draft
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="savedAuction">
          <v-btn class="button elevation-5 button-outlined" @click="$emit('loadDraft')">
            load draft
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="savedAuction">
          <v-btn class="button elevation-5" color="error" @click="$emit('deleteDraft')">
            delete draft
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="button elevation-5"
            @click="createAuction"
            color="primary"
            :disabled="createAuctionDisable"
          >
            create auction
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="end" style="gap: 5px" v-else>
        <v-col cols="auto">
          <v-btn
            class="button elevation-5"
            @click="$emit('updateAuction')"
            color="primary"
            :disabled="createAuctionDisable"
          >
            update auction
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: ["auctionFlag", "invalid", "flagModalUnreCust", "tableSelected", "newOrderFlag"],
  created() {
    this.auctionId = this.$route.query.id;
  },
  data() {
    return {
      auctionId: null,
    };
  },
  computed: {
    ...mapState({
      batchInfo: (state) => state.moduleAuctions.batchInfo,
      auction_data: (state) => state.moduleAuctions.auction_data,
      savedAuction: (state) => state.moduleAuctions.savedAuction,
    }),
    createAuctionDisable() {
      return this.flagModalUnreCust || !this.tableSelected.length || this.invalid;
    },
  },
  methods: {
    ...mapMutations({
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
      setAuctionFormFlag: "moduleQtySumm/setAuctionFormFlag",
    }),

    returnAllOrders() {
      this.auctionFlag ? this.setAuctionFormFlag(false) : this.setNewOrderFlag(false);
    },
    createAuction() {
      this.$emit("createNewAuction");
    },
  },
};
</script>

<style lang="scss" scoped></style>
