var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-5"},[_c('v-col',{staticClass:"pa-3 px-5 text-center d-flex justify-end"},[_c('v-btn',{staticClass:"button elevation-5 ma-2",attrs:{"color":"primary"},on:{"click":_vm.saveAsDraft}},[_vm._v(" save as draft ")]),(_vm.draft !== null)?[_c('v-btn',{staticClass:"button elevation-5 ma-2",attrs:{"color":"primary"},on:{"click":_vm.loadDraft}},[_vm._v(" load draft ")]),_c('v-btn',{staticClass:"button elevation-5 ma-2",attrs:{"color":"error"},on:{"click":_vm.deleteDraft}},[_vm._v(" delete draft ")])]:_vm._e()],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pa-0 ma-auto overflow-y-auto",style:({ maxHeight: '80vh' }),model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',{staticClass:"pa-5"},_vm._l((_vm.stepperData),function({ title },index){return _c('v-stepper-step',{key:title,staticClass:"pa-0 text-capitalize font-weight-bold",attrs:{"complete":_vm.step > index + 1,"step":index + 1}},[_vm._v(" "+_vm._s(title)+" ")])}),1),_c('v-stepper-items',_vm._l((_vm.stepperData),function({ props, on, component, stepComponent, width },index){return _c('v-stepper-content',{key:index,staticClass:"ma-0 pa-3",attrs:{"step":index + 1}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid, errors, dirty }){return [_c('v-row',{staticClass:"flex-column ma-auto elevation-5 dark_blue align-center py-5",style:({
                    width: width,
                  })},[(index + 1 === _vm.step)?_c(component,_vm._g(_vm._b({tag:"component",attrs:{"step":_vm.step,"errors":errors,"ObserverDirty":dirty,"ObserverInvalid":invalid}},'component',props,false),on)):_vm._e(),_c('v-col',{staticClass:"pt-5 d-flex justify-space-between",attrs:{"cols":"9"}},[(stepComponent != 1)?_c('v-btn',{on:{"click":function($event){return _vm.backStep(stepComponent)}}},[_vm._v(" Back ")]):_vm._e(),(stepComponent === 3)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveInventory}},[_vm._v(" Add inventory ")]):_vm._e(),(stepComponent !== 6 && stepComponent !== 1)?_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.errorBalance(stepComponent) &&
                        (stepComponent !== 4 || _vm.checkError(errors) === null),"color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[(stepComponent !== 6 && stepComponent !== 1)?_c('v-btn',{staticClass:"button button--outlined",attrs:{"disabled":((stepComponent === 2 ||
                                stepComponent === 4 ||
                                stepComponent === 5) &&
                                invalid) ||
                              (stepComponent === 5 &&
                                _vm.creditsSelectedBalance > _vm.totalAmount) ||
                              (stepComponent === 4 && !!_vm.loadingFormOrdersTable)},on:{"click":function($event){return _vm.updateStep(stepComponent)}}},[_vm._v(" Continue ")]):_vm._e()],1)]}}],null,true)},[_c('span',{staticClass:"font-weight-bold dark_blue--text"},[_vm._v(_vm._s(stepComponent === 5 && _vm.creditsSelectedBalance > _vm.totalAmount ? "Credit amount cannot be greater than the total amount of the order" : _vm.checkError(errors)))])]):_vm._e(),(stepComponent === 6)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.sendData}},[_vm._v(" Create ")]):_vm._e()],1),_c('v-slide-y-transition',[(stepComponent === 4 && _vm.flagNewOrderTable)?_c('v-col',{staticClass:"my-3 pa-5 create-order-table",staticStyle:{"position":"relative","bottom":"-20"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","flex-flow":"column","max-height":"400px"}},[_c('div',[_c('v-btn',{staticClass:"ma-2 lighten-4 elevation-5",staticStyle:{"float":"right"},attrs:{"outlined":"","small":""},on:{"click":_vm.setNewOrderTable}},[_vm._v(" Close ")])],1),_c('CreateOrderTable',{on:{"formOrderTableSelectedItem":_vm.formOrderTableSelectedItem}})],1)]):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }