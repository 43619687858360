<template>
  <v-col cols="9">
    <v-row class="pa-0" justify="space-between">
      <v-col cols="12" style="max-height: 700px">
        <v-row justify="center" align="center" class="losiento" v-broker>
          <v-col cols="auto" class="d-flex justify-center align-center">
            <span class="pa-1 px-2"> Change Payment Terms Entity </span>
            <v-checkbox v-model="changePaymentOrg"> </v-checkbox>
          </v-col>

          <v-col cols="auto">
            <v-autocomplete
              v-model="vendorValue"
              :items="vendorOrg"
              color="white"
              dense
              outlined
              flat
              background-color="#20212E"
              hide-details
              class="rounded-0 lighten-2 pa-0 ma-0 text-center aaa"
              item-text="nameID"
              item-value="organization_id"
              :disabled="!changePaymentOrg"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-divider class="py-1"></v-divider>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <span class="text-capitalize text-h6">customer data</span>
        </v-row>
        <v-row class="pa-0 space-between align-center">
          <v-col cols="12">
            <v-row style="flex-wrap: nowrap">
              <v-col cols="10" class="py-1">
                <v-text-field
                  class="ma-0"
                  hide-details
                  type="text"
                  outlined
                  dense
                  clearable
                  :prepend-inner-icon="'mdi-magnify'"
                  single-line
                  v-model.trim="searchInput"
                  mobile-breakpoint="0"
                >
                  <template v-slot:label
                    ><span class="pa-0 ma-0"> Search </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="auto" class="pa-2">
                <v-btn color="primary" small @click="setCustomerData()">
                  Add new Customer</v-btn
                >
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-data-table
              class="elevation-5 main-table rounded-0 overflow-y-auto"
              style="max-height: 600px"
              dense
              fixed-header
              hide-default-footer
              height="100%"
              :header-props="{ sortIcon: 'mdi-chevron-up' }"
              :headers="headerTable"
              :items="filteredItems"
              disable-pagination
              @click:row="setCustomerData"
            >
              <!-- <template v-slot:item="{ item }">
                <tr @click="$emit('showCustomer', item)">
                  <td v-for="(header, key) in headerTable" class="primary-tr" :key="key">
                    <span
                      :style="{ height: '30px', border: 'none' }"
                      class="ma-0 rounded-0"
                    >
                      {{ item[header.value] }}
                    </span>
                  </td>
                </tr>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { CUSTOMER_HEADER } from "@/helpers/mocks/orders.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import { search } from "@/utils/panelAction.js";

export default {
  created() {
    if (!this.list_organizations.length) this.getsALLOrganizations();
    this.$emit("setCustomerData", {});
  },
  computed: {
    ...mapState({
      list_organizations: (state) => state.moduleOrganizations.list_organizations,
      list_organizations_vendor: (state) =>
        state.moduleOrganizations.list_organizations_vendor,
    }),
    ...mapGetters({
      filteredListCustomers: "moduleOrganizations/filteredListCustomers",
    }),
    headerTable() {
      return generateHeaderData(this.CUSTOMER_HEADER);
    },
    filteredItems() {
      return search({
        searchInput: this.searchInput,
        header: this.CUSTOMER_HEADER,
        arr: this.filteredListCustomers,
      });
    },
    vendorOrg() {
      return this.list_organizations_vendor.map((x) => {
        return { ...x, nameID: `${x.organization_descr}-${x.organization_id}` };
      });
    },
  },
  watch: {
    vendorValue() {
      this.setEntityBill(this.vendorValue);
    },
    changePaymentOrg() {
      this.setEntityBill(null);
    },
  },
  data() {
    return {
      CUSTOMER_HEADER,
      searchInput: "",
      changePaymentOrg: false,
      vendorValue: "",
    };
  },
  methods: {
    ...mapMutations({
      setEntityBill: "moduleOrders/setEntityBill",
      setCredits: "moduleOrders/setCredits",
      setCreditsTotal: "moduleOrders/setCreditsTotal",
    }),
    ...mapActions({
      getsALLOrganizations: "moduleOrganizations/getsALLOrganizations",
      getCustBalanceCredit: "moduleOrders/moduleDetails/getCustBalanceCredit",
    }),
    async setCustomerData(item) {
      let customerData = {
        transStepId: 2,
      };

      if (item) {
        let {
          organization_address,
          organization_id,
          externalCustomerNumber,
          organization_descr,
          primary_email,
        } = item;

        customerData = {
          customerEntityId: organization_id,
          organization_address: organization_address,
          externalCustomerNumber: externalCustomerNumber,
          primary_email: primary_email.split(";")[0],
          customerName: organization_descr,
        };
        let result = await this.getCustBalanceCredit({
          custID: organization_id,
        });

        this.setCredits(result.data.credits);
        this.setCreditsTotal({
          amount: result.data.amount,
          balance: result.data.balance,
        });
      }

      this.$emit("setCustomerData", customerData);
      this.$emit("nextStep");
    },
  },
};
</script>

<style scoped lang="scss">
.w-50 {
  width: 90%;
  margin: auto;
}
</style>
