<template>
  <v-container style="max-width: 100%">
    <v-row class="ma-0 dark-blue pa-0 fill-height" style="width: 100%" v-if="!preview">
      <v-col
        cols="2"
        class="px-2 py-2 ma-0 d-flex align-center"
        v-if="!flagIsGateBuySoft"
      >
        <v-btn
          color="#20212E"
          solo
          class="elevation-5 pa-2 button"
          block
          @click="flagNewOrderTable = true"
          :disabled="flagNewOrderTable"
        >
          <span> New</span>
        </v-btn>
      </v-col>
      <v-col cols="2" class="px-2 py-2 ma-0 d-flex align-center" v-if="flagIsGateBuySoft">
        <v-btn
          color="#20212E"
          solo
          class="elevation-5 pa-2 button"
          block
          @click="flagModalPasteContainers = true"
        >
          <span> Paste</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="my-sticky-header-table overflow-y-auto">
        <v-data-table
          dense
          hide-default-footer
          class="elevation-5 rounded-0 pa-0 ma-0 fill-height overflow-y-auto main-table"
          fixed-header
          style="height: 25vh"
          :headers="header"
          :items="data"
          :items-per-page="-1"
          :loading="loading"
          :mobile-breakpoint="0"
        >
          <!-- :item-class="itemClassError" -->
          <template v-slot:[`item.actions`]="{ item, index }">
            <div v-if="item.added" class="d-flex justify-center align-center">
              <v-checkbox v-model="item.addInventory" class="ma-0" hide-details />
            </div>
            <v-row class="flex-nowrap" v-else>
              <v-col
                class="pa-0 ma-0"
                v-for="{ icon, eventName, name } in tableActions"
                :key="icon"
              >
                <v-btn
                  color="#20212E"
                  class="lighten-2 rounded-0"
                  block
                  v-if="item.newRow && name !== 'copy'"
                  @click="eventName(index)"
                >
                  <v-icon small dark>{{ icon }}</v-icon>
                </v-btn>

                <v-btn
                  color="#20212E"
                  class="lighten-2 rounded-0"
                  block
                  v-if="!item.newRow"
                  @click="eventName(index)"
                >
                  <v-icon small dark>{{ icon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="flagIsGateBuySoft && !allContainers.includes(item.ContainerNo)"
                cols="auto"
                class="d-flex"
              >
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on }">
                    <v-btn color="#20212E" v-on="on" class="lighten-2 rounded-0" block>
                      <v-icon small dark color="red" class="ma-auto">mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ `Container ${item.ContainerNo}  is not available` }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.depot`]="{ item, index }">
            <span v-if="item.added">
              {{ item.depot }}
            </span>
            <v-select
              v-else
              @input="dropDownChange($event, index, 'depot')"
              :value="item.depot"
              :items="getInventoryQtySummsAvailablesDepot"
              label="Depot"
              single-line
              dense
              solo
              background-color="#20212E"
              hide-details
              class="lighten-2 pa-0 ma-0 elevation-5 text-center droppy"
            >
            </v-select>
          </template>
          <template v-slot:[`item.equipmentSize`]="{ item, index }">
            <span v-if="item.added">
              {{ item.equipmentSize }}
            </span>
            <v-select
              v-else
              :items="getInventoryQtySummsAvailablesDepotValues[item.depot]"
              label="Size"
              item-value="Size"
              item-text="Size"
              single-line
              dense
              solo
              background-color="#20212E"
              hide-details
              class="lighten-2 pa-0 ma-0 elevation-5 text-center droppy"
              return-object
              v-model="item.equipmentSize"
              @input="dropDownChange($event, index, 'Size')"
            >
            </v-select>
          </template>
          <template v-slot:[`item.equipmentType`]="{ item, index }">
            <span v-if="item.added">
              {{ item.equipmentType }}
            </span>
            <v-select
              v-else
              :items="
                getTypeInventoryByDepotSize(
                  getInventoryQtySummsAvailablesDepotValues[item.depot],
                  item.equipmentSize
                )
              "
              label="Type"
              item-value="Type"
              item-text="Type"
              single-line
              dense
              solo
              background-color="#20212E"
              hide-details
              class="lighten-2 pa-0 ma-0 elevation-5 text-center droppy"
              return-object
              v-model="item.equipmentType"
              @input="dropDownChange($event, index, 'Type')"
            >
            </v-select>
          </template>
          <template v-slot:[`item.equipmentCondition`]="{ item, index }">
            <span v-if="item.added">
              {{ item.equipmentCondition }}
            </span>
            <v-select
              v-else
              :items="
                getCndtnInventoryByDepotSizeType(
                  getInventoryQtySummsAvailablesDepotValues[item.depot],
                  item.equipmentSize,
                  item.equipmentType
                )
              "
              label="Condition"
              item-value="Cndtn"
              item-text="Cndtn"
              single-line
              dense
              solo
              background-color="#20212E"
              hide-details
              class="lighten-2 pa-0 ma-0 elevation-5 text-center droppy"
              return-object
              v-model="item.equipmentCondition"
              @input="dropDownChange($event, index, 'Cndtn')"
            >
            </v-select>
          </template>
          <template v-slot:[`item.ContainerNo`]="{ item, index }">
            <span v-if="item.added">
              {{ item.depot }}
            </span>
            <v-autocomplete
              v-else
              :value="item.ContainerNo"
              :items="[
                ...uniqueContainers(
                  containers[
                    `${item.depot}_${item.equipmentSize}_${item.equipmentType}_${item.equipmentCondition}`
                  ]
                ),
                item.ContainerNo !== '' ? item.ContainerNo : null,
              ]"
              :search-input.sync="searchContainerNo[index]"
              :disabled="loading"
              color="white"
              dense
              solo
              background-color="#20212E"
              hide-details
              item-value="EquipmentID"
              item-text="EquipmentID"
              class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center droppy"
              @input="(value) => setQty(value, item)"
              return-object
            >
            </v-autocomplete>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <span class="ma-0 rounded-0 pa-3">
              {{ item.buyerPrice * item.qty }}
            </span>
          </template>
          <template v-slot:[`item.qty`]="{ item, header, index }">
            <span v-if="item.added">
              <v-tooltip
                bottom
                :disabled="
                  errors &&
                  errors[`${header.text}_${index}`] &&
                  !errors[`${header.text}_${index}`].length
                "
                color="warning"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    :rules="`${header.rules}|max_num:${item.maxQty}`"
                    :name="`${header.text}_${index}`"
                    immediate
                    v-slot="{ dirty, invalid }"
                  >
                    <input
                      :value="item[header.value]"
                      @input="controlQtyAdded($event, item, index)"
                      class="non-outlined ma-0 rounded-0 pa-0"
                      :class="dirty && invalid ? 'outlined-error' : ''"
                      :style="{
                        height: '100%',
                        backgroundColor:
                          item.ContainerNo.length > 0 ? '#262737' : '#303030',

                        outline: 'none',
                        color: '#ffff',
                        caretColor: '#3284f4',
                        width: '100%',
                      }"
                      :disabled="item.ContainerNo.length > 0"
                      v-on="on"
                    />
                  </ValidationProvider>
                </template>
                <span class="font-weight-bold dark_blue--text">
                  {{
                    errors &&
                    !!errors[`${header.text}_${index}`] &&
                    !!errors[`${header.text}_${index}`].length
                      ? errors[`${header.text}_${index}`][0].replace(`_${index}`, "")
                      : ""
                  }}
                </span>
              </v-tooltip>
            </span>
            <v-tooltip
              v-else
              bottom
              :disabled="
                errors &&
                errors[`${header.text}_${index}`] &&
                !errors[`${header.text}_${index}`].length
              "
              color="warning"
            >
              <template v-slot:activator="{ on }">
                <ValidationProvider
                  :rules="`${header.rules}|max_num:${maxQtyValue(item, index)}`"
                  :name="`${header.text}_${index}`"
                  immediate
                  v-slot="{ invalid }"
                >
                  <input
                    :value="item[header.value]"
                    @input="controlQty($event, item, index)"
                    class="non-outlined ma-0 rounded-0 pa-0"
                    :class="invalid ? 'outlined-error' : ''"
                    :style="{
                      height: '100%',
                      backgroundColor:
                        item.ContainerNo.length > 0 ? '#262737' : '#303030',
                      outline: 'none',
                      color: '#ffff',
                      caretColor: '#3284f4',
                      width: '100%',
                    }"
                    :disabled="item.ContainerNo.length > 0"
                    v-on="on"
                  />
                </ValidationProvider>
              </template>
              <span class="font-weight-bold dark_blue--text">
                {{
                  errors &&
                  !!errors[`${header.text}_${index}`] &&
                  !!errors[`${header.text}_${index}`].length
                    ? errors[`${header.text}_${index}`][0].replace(`_${index}`, "")
                    : ""
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.buyerPrice`]="{ item, header, index }">
            <v-tooltip
              bottom
              :disabled="
                errors &&
                errors[`${header.text}_${index}`] &&
                !errors[`${header.text}_${index}`].length
              "
              color="warning"
            >
              <template v-slot:activator="{ on }">
                <ValidationProvider
                  :rules="item.addInventory === false ? '' : header.rules"
                  :name="`${header.text}_${index}`"
                  immediate
                >
                  <v-text-field
                    :name="header.text"
                    class="ma-0 rounded-0 non-outlined"
                    outlined
                    full-width
                    hide-details
                    dense
                    background-color="#303030"
                    v-model="item[header.value]"
                    @input="updateTotal(item)"
                    v-on="on"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <span class="font-weight-bold dark_blue--text">
                {{
                  errors &&
                  !!errors[`${header.text}_${index}`] &&
                  !!errors[`${header.text}_${index}`].length
                    ? errors[`${header.text}_${index}`][0].replace(`_${index}`, "")
                    : ""
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.ManufactureYear`]="{ item, header }">
            {{ item[header.value] }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <template v-if="!preview">
      <v-row
        class="flex-column ma-auto elevation-5 dark_blue align-center py-5 create-order-table"
        v-if="flagNewOrderTable"
      >
        <v-slide-y-transition>
          <v-col cols="12" v-cloak>
            <v-row justify="end">
              <v-btn
                @click="flagNewOrderTable = false"
                class="ma-2 lighten-4 elevation-5"
                outlined
                small
                style="float: right"
              >
                Close
              </v-btn>
            </v-row>
            <v-row>
              <CreateOrderTable
                v-cloak
                :loading="qtySummTableLoading"
                @formOrderTableSelectedItem="formOrderTableSelectedItem"
              />
            </v-row>
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </template>

    <ModalPasteContainers
      title="Paste Containers"
      v-if="flagModalPasteContainers"
      :show="flagModalPasteContainers"
      @closeModalPasteContainers="flagModalPasteContainers = false"
      @loadOrdersData="loadOrdersData"
    />
  </v-container>
</template>

<script>
import Table from "@/components/General/Table.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { ValidationProvider } from "vee-validate";
import { formOrdersTable } from "@/helpers/mocks/orders.js";
import { available_container_no } from "@/helpers/api/Orders/index.js";
import { getAllHoldRate } from "@/helpers/api/Organizations/index.js";
import CreateOrderTable from "@/components/InventoryQtySumm/CreateOrderTable.vue";
import { get_inventory_qty_summs, get_orders_and_quotas } from "@/helpers/api/indexv2.js";
import ModalPasteContainers from "@/components/OM/NewOrder/ModalPasteContainers.vue";

export default {
  props: [
    "disabled",
    "errors",
    "ordersData",
    "customerData",
    "preview",
    "orderDataContainers",
    "orderTypeId",
  ],
  components: {
    Table,
    ValidationProvider,
    CreateOrderTable,
    ModalPasteContainers,
  },
  async created() {
    if (!this.inventoryQtySumms.length) {
      this.qtySummTableLoading = true;
      let res = await Promise.all([get_inventory_qty_summs(), this.getDepotsAll()]);
      this.setInventoryQtySumms(res[0].data.mergedData);
      this.qtySummTableLoading = false;
    }
    if (this.ordersData.length) {
      this.data = JSON.parse(JSON.stringify(this.ordersData));
      this.containers = JSON.parse(JSON.stringify(this.orderDataContainers));
    }

    this.flagIsGateBuySoft = await this.isGateBuySoft(this.orderTypeId);

    if (this.flagIsGateBuySoft) {
      this.data = [];
      try {
        const [rates, quotas] = await Promise.all([
          getAllHoldRate(),
          get_orders_and_quotas(this.customerData.customerEntityId),
        ]);
        this.rates = rates;

        this.ordersAndQuotas = quotas.data.quotas.map((x) => {
          let orderFiltered = quotas.data.orders.filter(
            (item) =>
              `${x.Location}_${x.Size}_${x.Type}` ===
              `${item.Location}_${item.Size}_${item.Type}`
          );
          const sold = orderFiltered.length ? orderFiltered[0].qtySold : 0;

          return {
            ...x,
            orderSold: sold,
            limit: Number(x.quota) - Number(sold),
          };
        });
      } catch (error) {
        console.log(error);
      }
    }

    this.flagNewOrderTable = this.flagIsGateBuySoft ? false : true;
  },
  computed: {
    ...mapState({
      allDepots: (state) => state.moduleOrders.allDepots,
      inventoryQtySumms: (state) => state.moduleQtySumm.inventoryQtySumms,
    }),
    ...mapGetters({
      getInventoryQtySummsAvailables: "moduleQtySumm/getInventoryQtySummsAvailables",
      getInventoryQtySummsAvailablesQtys:
        "moduleQtySumm/getInventoryQtySummsAvailablesQtys",
    }),
    header() {
      return this.generateHeaderData(formOrdersTable({ depotSelects: this.allDepots }));
    },

    maxQtyValues() {
      return this.data.reduce((acc, item) => {
        if (
          acc.hasOwnProperty(`${item.depot}_${item.equipmentSize}_${item.equipmentType}`)
        ) {
          acc[`${item.depot}_${item.equipmentSize}_${item.equipmentType}`] =
            acc[`${item.depot}_${item.equipmentSize}_${item.equipmentType}`] - item.qty;
        } else {
          acc[`${item.depot}_${item.equipmentSize}_${item.equipmentType}`] =
            this.getInventoryQtySummsAvailablesQtys[
              `${item.depot}_${item.equipmentSize}_${item.equipmentType}`
            ] - item.qty;
        }

        return acc;
      }, {});
    },
    getInventoryQtySummsAvailablesDepotValues() {
      let obj = {};
      this.getInventoryQtySummsAvailables.map((x) => {
        if (!obj[`${x.Depot}`]) {
          obj[`${x.Depot}`] = [];
        }
        obj[`${x.Depot}`].push(x);
      });
      return obj;
    },
    getInventoryQtySummsAvailablesDepot() {
      return Object.keys(this.getInventoryQtySummsAvailablesDepotValues);
    },
  },
  watch: {
    data: {
      deep: true,
      async handler() {
        this.$emit("setOrdersTableData", this.data);
      },
    },

    allDepots: {
      immediate: true,
      handler() {
        if (this.allDepots.length) {
          this.data.map(async (item, index) => {
            if (!this.data[index].depotId) {
              let depotCode = this.allDepots[0] ? this.allDepots[0].code : "";

              let DepotId = item.depot
                ? this.allDepots.filter((x) => x.code === item.depot)[0].id
                : this.allDepots[0].id;

              this.data[index]["depot"] = item["depot"] || depotCode;
              this.data[index]["depotId"] = DepotId;
            }
            {
              this.data[index]["depot"] = item["depot"];
            }
          });
        }
      },
    },
  },
  data() {
    return {
      flagModalPasteContainers: false,
      flagIsGateBuy: false,
      flagIsGateBuySoft: false,
      data: [],
      tableActions: [
        {
          icon: "mdi-clipboard-arrow-down",
          eventName: this.copyItem,
          name: "copy",
        },
        { icon: "mdi-delete", eventName: this.deleteItem, name: "delete" },
      ],
      items: [],
      infoDepot: {},
      containers: {},
      loading: false,
      searchContainerNo: [],
      flagNewOrderTable: false,
      qtySummTableLoading: false,
      rates: [],
      ordersAndQuotas: [],
      allContainers: [],
    };
  },
  methods: {
    generateHeaderData,
    ...mapMutations({
      setInventoryQtySumms: "moduleQtySumm/setInventoryQtySumms",
    }),
    ...mapActions({
      getDepotsAll: "moduleOrders/getDepotsAll",
      isGateBuy: "moduleOrders/isGateBuy",
      isGateBuySoft: "moduleOrders/isGateBuySoft",
    }),
    // qtyQuotaValidation(item, index) {
    //   return this.flagIsGateBuySoft
    //     ? `max_quota:${this.maxQtyValueQuota(item, index)}`
    //     : "";
    // },
    async loadOrdersData(data) {
      this.data = data;

      // // getInventoryQtySummsAvailablesDepot
      const containers = await Promise.all(
        this.data.map((x, index) => this.getContainers(x, index))
      );

      this.data.forEach((item, index) => {
        const value = {
          Size: item.equipmentSize,
          Type: item.equipmentType,
          Depot: item.depot,
        };
        this.proccesGBContainers(value, index);
      });

      const objContainers = this.data.reduce((acc, item, index) => {
        const key = `${item.depot}_${item.equipmentSize}_${item.equipmentType}_${item.equipmentCondition}`;

        acc[key] = acc[key]
          ? [
              ...acc[key],
              ...containers[index].filter(
                (x) => !acc[key].find((accItem) => accItem.EquipmentID === x.EquipmentID)
              ),
            ]
          : [...containers[index]];

        return acc;
      }, {});

      this.containers = objContainers;

      containers.forEach((x) => {
        this.allContainers = [this.allContainers, ...x.map((item) => item.EquipmentID)];
      });

      this.$emit("setOrdersTableDataContainer", this.containers);

      this.flagModalPasteContainers = false;
    },

    updateTotal(item) {
      item.total = item.qty * item.buyerPrice;
    },
    async dropDownChange(value, index, name) {
      this.data[index].ContainerNo = "";
      this.data[index].ManufactureYear = "";
      this.data[index].qty = "";

      switch (name) {
        case "depot":
          let depotValue = this.allDepots.filter((x) => x.code === value)[0];

          if (depotValue) {
            this.data[index].depotId = depotValue.id;
            this.data[index].depot = value;
            this.infoDepot = depotValue;
          }

          this.data[index].equipmentSize = this.getInventoryQtySummsAvailablesDepotValues[
            value
          ][0].Size;

          this.data[index].equipmentType = this.getInventoryQtySummsAvailablesDepotValues[
            value
          ][0].Type;

          this.data[
            index
          ].equipmentCondition = this.getInventoryQtySummsAvailablesDepotValues[
            value
          ][0].Cndtn;
          this.data[index].city = this.infoDepot.CityName;
          this.data[index].country = this.infoDepot.CountryAlhpa2;
          this.data[index].state = this.infoDepot.StateAlpha2;

          this.data[index].city = this.infoDepot.CityName;
          this.data[index].country = this.infoDepot.CountryAlhpa2;
          this.data[index].state = this.infoDepot.StateAlpha2;

          break;
        case "Size":
          this.data[index].equipmentSize = value.Size;
          this.data[index].equipmentType = this.getTypeInventoryByDepotSize(
            this.getInventoryQtySummsAvailablesDepotValues[this.data[index].depot],
            this.data[index].equipmentSize
          )[0].Type;

          this.data[index].equipmentCondition = this.getCndtnInventoryByDepotSizeType(
            this.getInventoryQtySummsAvailablesDepotValues[this.data[index].depot],
            this.data[index].equipmentSize,
            this.data[index].equipmentType
          )[0].Cndtn;
          break;
        case "Type":
          this.data[index].equipmentType = value.Type;
          this.data[index].equipmentCondition = this.getCndtnInventoryByDepotSizeType(
            this.getInventoryQtySummsAvailablesDepotValues[this.data[index].depot],
            this.data[index].equipmentSize,
            this.data[index].equipmentType
          )[0].Cndtn;

          break;
        case "Cndtn":
          this.data[index].equipmentCondition = value.Cndtn;
          break;
      }
      if (
        !this.containers[
          `${this.data[index]["depot"]}_${this.data[index]["equipmentSize"]}_${this.data[index]["equipmentType"]}_${this.data[index]["equipmentCondition"]}`
        ]
      ) {
        this.loading = true;
        this.containers[
          `${this.data[index]["depot"]}_${this.data[index]["equipmentSize"]}_${this.data[index]["equipmentType"]}_${this.data[index]["equipmentCondition"]}`
        ] = await this.getContainers(this.data[index], index);
        this.loading = false;
        this.$emit("setOrdersTableDataContainer", this.containers);
      }
    },
    copyItem(index) {
      let newItem = { ...this.data[index] };
      this.data.splice(index, 0, newItem);
    },
    deleteItem(index) {
      if (this.data.length > 1) {
        this.data.splice(index, 1);
      } else {
        alert("Order requires at least one line item.");
      }
    },
    setQty(value, item) {
      if (value) {
        item["ContainerNo"] = value.EquipmentID;
        item["ManufactureYear"] = value.MfgYear;
        item.qty = "1";
      } else {
        item["ContainerNo"] = "";
        item.qty = "";
        item["ManufactureYear"] = "";
      }
    },
    controlQty(event, item, index) {
      const value = event.target.value;
      let order = null;
      if (this.flagIsGateBuySoft) {
        order = this.ordersAndQuotas.orders.find(
          (order) =>
            order.Size === item.equipmentSize &&
            order.Type === item.equipmentType &&
            order.Location === item.depot
        );
      }
      if (order) {
        // const quota = this.getQuotaForSizeTypeLocation(
        //   order.Size,
        //   order.Type,
        //   order.Location,
        // );
        // const remainingQty = quota - order.qtySold;
        // if (
        //   value <= remainingQty &&
        //   value <=
        //     Number(this.data[index].qty) +
        //       Number(
        //         this.maxQtyValues[
        //           `${item.depot}_${item.equipmentSize}_${item.equipmentType}`
        //         ]
        //       )
        // ) {
        this.data[index].qty = value;
        this.data[index].total = this.data[index].qty * this.data[index].buyerPrice;
        // }
      } else {
        // if (
        //   value <=
        //   Number(this.data[index].qty) +
        //     Number(
        //       this.maxQtyValues[
        //         `${item.depot}_${item.equipmentSize}_${item.equipmentType}`
        //       ]
        //     )
        // ) {
        this.data[index].qty = value;
        this.data[index].total = this.data[index].qty * this.data[index].buyerPrice;
        // }
      }
      this.$forceUpdate();
    },
    getQuotaForSizeTypeLocation(size, type, location) {
      const quota = this.ordersAndQuotas.quotas.find(
        (quota) =>
          quota.Size === size && quota.Type === type && quota.Location === location
      );
      return quota ? quota.quota : null;
    },
    controlQtyAdded(event, item, index) {
      const value = event.target.value;

      this.data[index].qty = value;

      this.data[index].total = this.data[index].qty * this.data[index].buyerPrice;
      // this.$forceUpdate();
    },
    async getContainers(item) {
      let res = await available_container_no({
        status: 1,
        depot: item.depot,
        size: item.equipmentSize,
        cndtn: item.equipmentCondition,
        type: item.equipmentType,
      });

      return res.data;
    },
    getTypeInventoryByDepotSize(arr, size) {
      if (!arr) return [];
      return arr.filter((x) => x.Size === size);
    },
    getCndtnInventoryByDepotSizeType(arr, size, type) {
      if (!arr) return [];

      return arr.filter((x) => x.Size === size && x.Type === type);
    },
    uniqueContainers(containers) {
      if (containers) {
        let dataContainers = this.data.map((x) => x.ContainerNo);

        let values = containers.filter((entry) => {
          return !dataContainers.includes(entry.EquipmentID);
        });

        return values;
      } else {
        return [];
      }
    },
    proccesGBContainers(item, index) {
      if (this.flagIsGateBuySoft) {
        const dataFiltered = this.rates.filter(
          (x) =>
            x.Location &&
            Number(x.Size) === Number(item.Size) &&
            x.Type.toLocaleLowerCase() === item.Type.toLocaleLowerCase() &&
            item.Depot.toLocaleLowerCase() === x.Location.toLocaleLowerCase()
        );
        if (dataFiltered.length) {
          this.data[index].buyerPrice = dataFiltered[0].rate;
        }
      }
    },
    async formOrderTableSelectedItem(item) {
      let newItem = {
        equipmentSize: item.Size,
        equipmentType: item.Type,
        equipmentCondition: item.Cndtn,
        depot: item.Depot,
        country: item.CountryAlhpa2,
        state: item.StateAlpha2,
        city: item.CityName,
        depotId: item.DepotId,
        qty: "",
        buyerPrice: "",
        temType: "Container",
        newRow: false,
        ContainerNo: "",
        searchContainerNo: null,
      };

      this.data.push(newItem);
      const index = this.data.length - 1;

      this.proccesGBContainers(item, index);

      if (
        !this.containers[
          `${this.data[index]["depot"]}_${this.data[index]["equipmentSize"]}_${this.data[index]["equipmentType"]}_${this.data[index]["equipmentCondition"]}`
        ]
      ) {
        this.loading = true;
        this.containers[
          `${this.data[index]["depot"]}_${this.data[index]["equipmentSize"]}_${this.data[index]["equipmentType"]}_${this.data[index]["equipmentCondition"]}`
        ] = await this.getContainers(this.data[index], index);
        this.loading = false;
        this.$emit("setOrdersTableDataContainer", this.containers);
      }
      this.flagNewOrderTable = false;
    },
    maxQtyValue(item, index) {
      return (
        Number(this.data[index].qty) +
        Number(
          this.maxQtyValues[`${item.depot}_${item.equipmentSize}_${item.equipmentType}`]
        )
      );
    },
    // maxQtyValueQuota(item, index) {
    //   let order = null;

    //   if (this.flagIsGateBuySoft && this.ordersAndQuotas && this.ordersAndQuotas.orders) {
    //     order = this.ordersAndQuotas.orders.find(
    //       (order) =>
    //         order.Size === item.equipmentSize.toUpperCase() &&
    //         order.Type === item.equipmentType.toUpperCase() &&
    //         order.Location === item.depot.toUpperCase()
    //     );

    //     if (order) {
    //       const quota = this.getQuotaForSizeTypeLocation(
    //         order.Size,
    //         order.Type,
    //         order.Location
    //       );

    //       if (quota) {
    //         const remainingQty = quota - order.qtySold;

    //         return remainingQty < 0 ? 0 : remainingQty;
    //       }
    //       return null;
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss">
.my-sticky-header-table {
  max-height: 500px;
  height: 100%;
}

.create-order-table {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  max-height: 400px;
  overflow: auto;
  z-index: 999;
}
.item-class-error {
  td {
    border-color: red !important;
    border-width: 2px 0px 2px 0px !important;
    border-style: solid !important;
  }
  td:first-child {
    border-width: 2px 0px 2px 2px !important;
  }
  td:last-child {
    border-width: 2px 2px 2px 0px !important;
  }
}
</style>
