<template>
  <div class="elevation-5 pa-2">
    <v-row style="width: 100%" class="pa-0">
      <v-col cols="3" class="elevation-5 pa-2">
        <FormOrders
          class="px-5"
          :customerData="customerData"
          :preview="true"
          :disabled="false"
          :col="12"
          @setCustomerData="(data) => $emit('setCustomerData', data)"
        />
      </v-col>
      <v-col class="d-flex flex-column ma-0 pa-4 elevation-5" cols="8">
        <FormOrdersTable
          :ordersData="ordersData"
          :orderDataContainers="orderDataContainers"
          :disabled="true"
          :errors="errors"
          :preview="true"
          @setOrdersTableData="(data) => $emit('setOrdersTableData', data)"
          @setOrdersTableDataContainer="
            (data) => $emit('setOrdersTableDataContainer', data)
          "
        />
        <span
          class="text-caprion font-weight-bold py-2 primary black--text"
          style="display: flex; flex-flow: row-reverse; padding-right: 2em"
          >Qty: {{ totalValues.totalQty }}
        </span>
        <span
          class="text-caprion font-weight-bold py-2 primary black--text"
          style="display: flex; flex-flow: row-reverse; padding-right: 2em"
          >Total Amount: {{ totalValues.totalAmount }}
        </span>
        <span
          v-gate-buy="customerData.orderTypeId"
          class="text-caprion font-weight-bold py-2 primary black--text text-end"
          style="display: flex; flex-flow: row-reverse; padding-right: 2em"
          >Total Credits: {{ totalBalanceCredits }}
        </span>
      </v-col>
    </v-row>

    <v-row class="pa-5" justify="end">
      <v-radio-group v-model="customerData['transStepId']" row>
        <v-radio label="Open Order" :value="2"></v-radio>
        <v-radio label="Quote" :value="1" v-if="false"></v-radio>
      </v-radio-group>
    </v-row>
  </div>
</template>

<script>
import FormOrders from "@/components/OM/AllOrders/FormOrders.vue";
import FormOrdersTable from "@/components/OM/AllOrders/FormOrdersTable.vue";

import { mapState } from "vuex";
export default {
  components: {
    FormOrders,
    FormOrdersTable,
  },
  props: [
    "customerFields",
    "customFields",
    "errors",
    "customerData",
    "ordersData",
    "orderDataContainers",
    "creditsSelected",
  ],
  computed: {
    // ...mapState
    // // orderTypeData() {
    // //   const res = this.ordersType.filter((x) => x.id === this.customerData.orderTypeId);
    // //   return res;
    // // },
    totalBalanceCredits() {
      let total = 0;
      this.creditsSelected.map((x) => (total += Number(x.balance)));
      return total;
    },
    totalValues() {
      let totalAmount = 0;
      let totalQty = 0;
      this.ordersData.map(({ qty, buyerPrice, addInventory }) => {
        if (addInventory) {
          totalAmount += Number(qty) * Number(buyerPrice);
          totalQty += Number(qty);
        } else if (addInventory === undefined) {
          totalAmount += Number(qty) * Number(buyerPrice);
          totalQty += Number(qty);
        }
      });
      this.customerData["totalQty"] = totalQty;
      this.customerData["total"] = totalAmount;

      return {
        totalAmount,
        totalQty,
      };
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
