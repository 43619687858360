<template>
  <v-row class="dark-blue pa-0 justify-space-between align-center">
    <v-col cols="9">
      <v-text-field
        class="ma-0"
        hide-details
        type="text"
        outlined
        dense
        clearable
        :prepend-inner-icon="'mdi-magnify'"
        single-line
        v-model="searchInput"
      >
        <template v-slot:label><span class="pa-0 ma-0"> Search </span> </template>
      </v-text-field>
    </v-col>

    <v-col
      class="d-flex justify-end align-center col-ajust"
      v-if="tabSelectedIndex === 2"
    >
      <label class="v-label theme--dark" style="font-size: 14px !important">Closed</label>
      <v-switch
        :value="historySwitch"
        inset
        label="Canceled"
        class="ma-0 pa-0 history-switch"
        :class="historySwitch ? 'canceled-switch' : 'closed-switch'"
        hide-details
        hide-spin-buttons
        @click="changeHistorySwitch"
      >
        <template #label> </template>
      </v-switch>
    </v-col>
    <!-- <v-col class="d-flex justify-end col-ajust">
    </v-col> -->
    <v-col class="d-flex justify-end col-ajust py-1" style="gap: 5px">
      <!-- <FilterLotLevel :columns="columnsFilter" /> -->
      <v-btn class="button elevation-5" color="primary" @click="newOrder">
        New Order
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { HEADER } from "@/helpers/mocks/orders";
import FilterLotLevel from "@/components/OM/AllOrders/FilterLotLevel.vue";
export default {
  components: {
    FilterLotLevel,
  },
  computed: {
    ...mapState({
      tabSelectedIndex: (state) => state.moduleOrders.tabSelected?.index,
      historySwitch: (state) => state.moduleOrders.historySwitch,
    }),

    columnsFilter() {
      return [{ text: "Releases", value: "rn2_release_ref" }];
    },
  },
  data() {
    return {
      searchInput: "",
    };
  },

  watch: {
    docNo() {
      this.searchInput = "";
    },
    searchInput() {
      this.setSearchInput(this.searchInput);
    },
  },
  methods: {
    ...mapMutations({
      setSearchInput: "moduleOrders/setSearchInput",
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
      setNewOrderFlagCreateNew: "moduleOrders/setNewOrderFlagCreateNew",
      setHistorySwitch: "moduleOrders/setHistorySwitch",
    }),
    newOrder() {
      this.$router.push("/orders/new-order");

      // this.setNewOrderFlag(true);
      // this.setNewOrderFlagCreateNew(true);
    },
    changeHistorySwitch(value) {
      this.setHistorySwitch(!this.historySwitch);
    },
  },
};
</script>

<style lang="scss">
.history-switch {
  .v-input--selection-controls__input {
    margin-left: 14px !important;
  }
}
.canceled-switch {
  .v-input--switch__track.theme--dark {
    background-color: var(--v-accent-lighten2);
  }
  .v-input--switch__thumb.theme--dark {
    background-color: var(--v-accent-lighten2);
  }
}
.closed-switch {
  .v-input--switch__track {
    background-color: var(--v-error-lighten2);
  }
  .v-input--switch__thumb.theme--dark {
    background-color: var(--v-error-lighten2);
  }
}
</style>
