<template>
  <BaseForm class="pa-0">
    <template v-slot:fields>
      <v-row v-for="k in obj" :key="k.id">
        <v-col class="pa-1">
          <span class="text-subtitle-1 font-weight-bold text-capitalize">{{
            k.FieldName
          }}</span>
          <DatePicker
            class="py-2"
            v-if="k.type === 'DATE'"
            v-model="k.value"
            :data="obj"
            :keyData="value"
            :title="label"
            :existingModel="obj[value]"
          ></DatePicker>
          <v-text-field
            class="py-1 customField-icon"
            v-else
            type="text"
            v-model="k.value"
            outlined
            dense
            background-color="darker_blue"
            color="white"
            hide-details
          >
            <template v-slot:append>
              <v-tooltip top color="dark_blue">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="px-2 icon-mt"
                    :style="{ cursor: 'default' }"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span class="text-subtitle-2">{{ k.FieldType }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>

    <template v-slot:buttons>
      <slot name="buttons" :item="obj"></slot>
    </template>
  </BaseForm>
</template>
<!-- DATE, DATETIME, CURRENCY, NUMBER, BOOLEAN, STRING -->
<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";

export default {
  components: {
    BaseForm,
    DatePicker,
  },
  props: ["customFields"],
  data() {
    return {
      obj: {},
    };
  },
  watch: {
    customFields: {
      immediate: true,
      handler() {
        this.obj = [...this.customFields];
      },
    },
  },
};
</script>

<style lang="scss">
.customField-icon {
  .v-input__append-inner {
    margin: auto !important;
  }
}
</style>
