<template>
  <v-container class="pa-0 text-caption font-weight-bold elevation-5 py-1">
    <v-row
      class="primary text-center"
      v-for="{ title, key } in dataTemplate"
      :key="key"
    >
      <v-col cols="6" class="py-1" :style="{ border: 'solid thin #262737' }"
        ><span class="ma-auto"> {{ title }} </span>
      </v-col>

      <v-col cols="6" class="py-1" :style="{ border: 'solid thin #262737' }"
        ><span> {{ `${parseFloat(data[key]).toFixed(2)}` }} </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      dataTemplate: [
        {
          title: "Total Credits",
          key: "totalCreated",
        },
        {
          title: "Credits Applied",
          key: "creditApplied",
        },
        {
          title: "Available Credits",
          key: "available",
        },
      ],
    };
  },
};
</script>

<style></style>
