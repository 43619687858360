<template>
  <v-row justify="center">
    <v-col :cols="newCustomer ? 10 : 10">
      <v-row class="align-center justify-center overflow-y-auto">
        <v-col
          class="pa-1"
          :cols="col || 10"
          v-for="{ title, value, type, drowpdownValues, rules, readonly } in ui"
          :key="title"
          :class="[type == 'checkbox' ? 'd-flex' : '']"
        >
          <span class="text-subtitle-1 font-weight-bold text-capitalize">
            {{ title }}
          </span>
          <v-tooltip
            bottom
            :disabled="!(errors && errors[title] && errors[title].length)"
            color="warning"
          >
            <template v-slot:activator="{ on }">
              <ValidationProvider
                :rules="rules"
                :name="title"
                v-slot="{ valid }"
                immediate
              >
                <v-select
                  v-if="type === 'dropdown'"
                  v-model="data[value]"
                  :items="drowpdownValues.values"
                  :item-text="drowpdownValues.text ? drowpdownValues.text : ''"
                  :item-value="drowpdownValues.value ? drowpdownValues.value : ''"
                  dense
                  solo
                  background-color="#20212E"
                  hide-details
                  class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
                  :class="!valid ? 'outlined-error' : ''"
                  :error="!valid"
                  :disabled="!!disabled"
                >
                </v-select>
                <v-text-field
                  v-else
                  name="thunder"
                  v-model="data[value]"
                  class="ma-0 pt-1"
                  type="text"
                  outlined
                  dense
                  hide-details
                  :disabled="!!disabled"
                  :error="!loading && !valid"
                  background-color="#20212E"
                  :readonly="readonly"
                  v-on="on"
                >
                </v-text-field>
              </ValidationProvider>
            </template>
            <span class="font-weight-bold dark_blue--text">{{
              !!(errors && errors[title] && errors[title].length) ? errors[title][0] : ""
            }}</span>
          </v-tooltip>
        </v-col>
        <v-col :cols="col || 10" v-if="data.customFields && data.customFields.length">
          <BaseSpanLine title="Custom Fields" />

          <CustomFieldsFormNewOrder3
            :customFields="data.customFields"
            class="transparent elevation-0"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { ValidationProvider } from "vee-validate";
import BaseSpanLine from "@/components/General/BaseSpanLine.vue";

import CustomFieldsFormNewOrder3 from "@/components/OM/CustomFields/FormNewOrder3.vue";
import BaseForm from "@/components/General/BaseForm.vue";
export default {
  props: ["disabled", "col", "errors", "customerData", "preview"],
  components: {
    ValidationProvider,
    BaseSpanLine,
    CustomFieldsFormNewOrder3,
    BaseForm,
  },
  async created() {
    this.loading = true;
    if (!this.customerData.flag) {
      await Promise.all([this.getOrdersTerm(), this.getOrdersType()]);
      const orderTypeId = await this.orderTypeId("Yard Buy");
      const creditTermId = await this.creditTermId("Cash in Advance");

      if (this.customerData.customerEntityId) {
        const customFields = await this.get_custom_field_by_org(
          this.customerData.customerEntityId
        );
        this.data = { orderTypeId, creditTermId, ...this.customerData, customFields };
      } else {
        this.data = { orderTypeId, creditTermId };
      }
    } else {
      this.data = { ...this.customerData };
    }
    this.loading = false;
  },
  computed: {
    ...mapState({
      ordersTerm: (state) => state.moduleOrders.ordersTerm,
      ordersType: (state) => state.moduleOrders.ordersType,
    }),
    ui() {
      return [
        {
          title: "Customer Name",
          value: "customerName",
          rules: "required|min:2",
          readonly: !this.newCustomer,
        },
        {
          title: "Email",
          value: "primary_email",
          rules: "required|email",
        },
        {
          title: "Address",
          value: "organization_address",
        },
        {
          title: "External Customer #",
          value: "externalCustomerNumber",
        },
        {
          title: "Customer PO",
          value: "custPoRef",
        },
        {
          title: "Contract Reference #",
          value: "contractNumberReference",
        },

        {
          title: "Order Type",
          value: "orderTypeId",
          type: "dropdown",
          drowpdownValues: {
            text: "typeDesc",
            value: "id",
            values: this.ordersType,
          },
        },
        {
          title: "Credit Term",
          value: "creditTermId",
          type: "dropdown",
          drowpdownValues: {
            text: "termsDesc",
            value: "id",
            values: this.ordersTerm,
          },
        },
      ];
    },
    newCustomer() {
      return !this.customerData.customerEntityId;
    },
  },
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$emit("setCustomerData", { ...this.data, flag: true });
      },
    },
  },
  methods: {
    ...mapActions({
      orderTypeId: "moduleOrders/orderTypeId",
      creditTermId: "moduleOrders/creditTermId",
      getOrdersTerm: "moduleOrders/getOrdersTerm",
      getOrdersType: "moduleOrders/getOrdersType",
      get_custom_field_by_org: "moduleOrders/get_custom_field_by_org",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
